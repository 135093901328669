import { Injectable } from '@angular/core';
import { NgxCookiebotService } from '@halloverden/ngx-cookiebot';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  #cookieBotService!: NgxCookiebotService;

  constructor(
    cookieBotService: NgxCookiebotService
  ) {
    this.#cookieBotService = cookieBotService;
  }

  init(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.#cookieBotService.onServiceReady$
        .pipe(
          filter((ready: boolean) => {
            return ready;
          }),
          tap(() => {
            this._setUpEventHandlers();
          }),
        )
        .subscribe();

      return resolve();
    });
  }

  private _setUpEventHandlers(): void {
    this.#cookieBotService.onConsentReady$
      .pipe(
        tap(() => {
          this._handleUserConsent();
        }),
      )
      .subscribe();
  }

  private _handleUserConsent(): void {
    if (this.#cookieBotService.cookiebot.consent.statistics) {
    }
  }
}
