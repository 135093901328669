import { FF_NGX_REQUEST_BUNDLE_API_CONFIGS, FfNgxRequestBundleConfig } from '@fagforbundet/ngx-components';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { environment } from '@environments/environment';
import idApiConfig from '@config/apis/id/id-api.config';

export const requestBundleConfig: FfNgxRequestBundleConfig = {
  debug: environment.debug.requestBundle,
};

export function apiConfigurationProvider(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: FF_NGX_REQUEST_BUNDLE_API_CONFIGS,
      useValue: [
        idApiConfig,
      ],
    },
  ]);
}
