import { Component } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeNb from '@angular/common/locales/nb';
import localeNbExtra from '@angular/common/locales/extra/nb';
import { SharedModule } from '@shared/shared.module';
import { RouterOutlet } from '@angular/router';

registerLocaleData(localeNb, 'nb', localeNbExtra);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [
    SharedModule,
    RouterOutlet,
  ],
})
export class AppComponent {
}
