import { ApplicationConfig, importProvidersFrom, inject, LOCALE_ID, provideAppInitializer } from '@angular/core';
import { apiConfigurationProvider, requestBundleConfig } from '@config/request-bundle.config';
import {
  ffNgxLoadingScreenProvider,
  ffNgxRequestsBundleProvider,
  ffNgxStorageBundleProvider,
  FfNgxStorageService,
} from '@fagforbundet/ngx-components';
import { storageBundleConfig } from '@config/storage-bundle.config';
import { ngxCookiebotProvider } from '@halloverden/ngx-cookiebot';
import { CookiebotConfig } from '@config/cookiebot.config';
import { CookieConsentService } from '@core/services/cookies/cookie-consent.service';
import { cookieConsentServiceFactory } from '@core/factories/cookie-consent-service.factory';
import { initializeInMemoryStoreFactory } from '@config/in-memory-store-initializer.factory';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { provideRouterWithConfig } from '@config/router.config';
import { SharedModule } from '@shared/shared.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { loadingScreenConfig } from '@config/loading-screen.config';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(SharedModule),
    apiConfigurationProvider(),
    ffNgxLoadingScreenProvider(loadingScreenConfig),
    ffNgxRequestsBundleProvider(requestBundleConfig),
    ffNgxStorageBundleProvider(storageBundleConfig),
    provideAnimations(),
    ngxCookiebotProvider(CookiebotConfig),
    CookieConsentService,
    provideAppInitializer(() => {
      const initializerFn = (cookieConsentServiceFactory)(inject(CookieConsentService));
      return initializerFn();
    }),
    provideAppInitializer(() => {
      const initializerFn = (initializeInMemoryStoreFactory)(inject(FfNgxStorageService));
      return initializerFn();
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'nb',
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideRouterWithConfig(),
  ],
};
